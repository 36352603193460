<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="病变编辑"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>病变部位</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/DAVF_detail_lesion_site"
          :value.sync="nerveDavfTableInfo.lesionSite"
        />
      </div>
      <div class="item-row">
        <span>Borden分型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/DAVF_detail_borden_type"
          :value.sync="nerveDavfTableInfo.bordenType"
        />
      </div>
      <div class="item-row">
        <span>合并症</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/DAVF_detail_complication"
          :value.sync="nerveDavfTableInfo.complication"
        />
      </div>
      <div class="item-row">
        <span>瘘口位置</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/DAVF_detail_fistula_location"
          :value.sync="nerveDavfTableInfo.fistulaLocation"
        />
      </div>
      <div class="item-row">
        <span>供血动脉名称</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          v-model="nerveDavfTableInfo.feedingArteryName"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>引流静脉名称</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          v-model="nerveDavfTableInfo.drainageVeinName"
        />
      </div>
      <div class="item-row">
        <div>
          <span>引流静脉类型</span>
          <div
            @mouseenter="isExitHover = true"
            @mouseleave="isExitHover = false"
            class="menus"
            style="float: right"
          >
            <i class="el-icon-warning" v-show="!isExitHover"></i>
            <i v-show="isExitHover" class="el-icon-warning" slot="reference" />
            <el-popover placement="bottom" width="400" trigger="manual" v-model="isExitHover">
              <img :src="exitActive" style="width: 400px; height: 250px" />
            </el-popover>
          </div>
        </div>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/DAVF_detail_drainage_vein_type"
          :value.sync="nerveDavfTableInfo.drainageVeinType"
        />
      </div>
      <div class="item-row">
        <span>既往有无介入治疗史</span>
        <cqt-radio
          :value.sync="nerveDavfTableInfo.hasInterventionalTherapy"
          :isReadonly="isReadonly"
        >
          <el-radio label="有">有</el-radio>
          <el-radio label="无">无</el-radio>
        </cqt-radio>
      </div>
      <div class="item-row">
        <span>既往弹簧圈/Onyx/球囊植入情况</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/DAVF_detail_has_coil_onyx_balloon"
          :value.sync="nerveDavfTableInfo.hasCoilOnyxBalloon"
        />
      </div>
      <div class="item-row">
        <span>既往弹簧圈/Onyx/球囊类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/DAVF_detail_coil_onyx_balloon_type"
          :value.sync="nerveDavfTableInfo.coilOnyxBalloonType"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>既往弹簧圈植入数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="个"
          v-model="nerveDavfTableInfo.coilCount"
        />
      </div>
      <div class="item-row">
        <span>既往Onyx胶植入数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="ml"
          v-model="nerveDavfTableInfo.onyxCount"
        />
      </div>
      <div class="item-row">
        <span>既往球囊数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="枚"
          v-model="nerveDavfTableInfo.balloonCount"
        />
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <el-col v-show="!isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-radio label="Onyx胶治疗" v-model="handlingOpinions">择期Onyx胶治疗</el-radio>
        <el-radio label="弹簧圈栓塞" v-model="handlingOpinions">择期弹簧圈栓塞</el-radio>
        <el-radio label="可解脱球囊治疗" v-model="handlingOpinions">择期可解脱球囊治疗</el-radio>
        <el-radio label="本次治疗" v-model="handlingOpinions">本次治疗</el-radio>
      </el-col>
      <el-col v-show="isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="handlingOpinions">
          {{ handlingOpinions }}
        </el-tag>
      </el-col>
      <el-col v-show="!isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <current-checkbox-select
          v-if="handlingOpinions === '本次治疗'"
          v-model="currentTreatment"
          :currentTreatment="this.currentTreatment"
        ></current-checkbox-select>
      </el-col>
      <el-col v-show="isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="currentTreatment">
          {{ currentTreatment }}
        </el-tag>
      </el-col>
    </div>
    <div class="button-panel">
      <el-button
        <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'NerveDavfInfoDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    nerveDavfId: {
      type: String,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      exitActive: require('@/assets/img/nerve/yinliujingmai.png'),
      isExitHover: false, //是否悬浮到图标上
      handlingOpinions: '',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: null,
      nerveDavfTableInfo: {
        nerveDavfId: '', //DAVF主表id
        lesionSite: '', //病变部位
        bordenType: '', //borden分型
        complication: '', //合并症
        fistulaLocation: '', //瘘口位置
        feedingArteryName: '', //供血动脉名称
        drainageVeinType: '', //引流静脉类型
        drainageVeinName: '', //引流静脉名称
        hasInterventionalTherapy: '', //既往有无介入治疗史
        hasCoilOnyxBalloon: '', //既往弹簧圈/Onyx/球囊植入情况
        coilOnyxBalloonType: '', //既往弹簧圈/Onyx/球囊类型
        coilCount: '', //既往弹簧圈植入数量
        onyxCount: '', //既往Onyx胶植入数量
        balloonCount: '', //既往球囊数量
        handlingOpinion: '' //处理意见
      },
      handlingOpinionsInfo: []
    }
  },
  watch: {
    handlingOpinions: {
      handler(val) {
        this.nerveDavfTableInfo.handlingOpinion = val
      }
    },

    currentTreatment: {
      handler(val) {
        this.nerveDavfTableInfo.handlingOpinion = '本次治疗'

        if (val && val.length > 0) {
          this.nerveDavfTableInfo.handlingOpinion = ['本次治疗', ...val.split('|')].join('+')
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.nerveDavfTableInfo = {}
      this.currentTreatment = ''
      if (bloodVessel) this.nerveDavfTableInfo = deepClone(bloodVessel)

      let sugs = this.nerveDavfTableInfo.handlingOpinion?.split('+') || []
      this.handlingOpinions = sugs[0] || ''
      if (sugs.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }

      this.isShow = true
    },
    Save() {
      if (this.isReadonly) return
      if (
        this.nerveDavfTableInfo.lesionSite === null ||
        this.nerveDavfTableInfo.lesionSite === ''
      ) {
        return this.$message.warning('病变部位不能为空')
      }
      // if (this.nerveDavfId != '-1' && this.nerveDavfId != '') {
      var idx = this.nerveDavfTableInfo.handlingOpinion?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.nerveDavfTableInfo.handlingOpinion.substr(0, idx + 1)
        var b = this.nerveDavfTableInfo.handlingOpinion.substr(idx + 2)
        this.nerveDavfTableInfo.handlingOpinion = a + b
      }

      this.$emit('save', this.nerveDavfTableInfo)
      this.Close()
      // }
    },

    Close() {
      this.isShow = false
      // this.handlingOpinions = ''
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
